import React from 'react';
import '../../styles/footer.css';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="right">
        <Link to="/agb">AGB</Link>
        <Link to="/terms">Terms of Agreement</Link>
        <Link to="/faq">FAQ</Link>
        <Link to="/impressum">Impressum</Link>
      </div>
    </footer>
  );
};

export default Footer;
