import React from 'react';
import '../styles/confirmationPage.css';

const ConfirmationPage = ({ data }) => {
  return (
    <div>
      <h2>That was it, just like that you have contacted whole industry.</h2>
      <p>We will proceed this email to approximately 30 companies.</p>
      <p>Hopefully you will get the best answers to your provided email.</p>
    </div>
  );
};

export default ConfirmationPage;
