// src/components/Form1.jsx
import React, { useState, useRef, useEffect } from 'react';
import '../styles/form1.css';
import ErrorMessage from '../components/common/ErrorMessage';
import axios from 'axios';

const Form1 = ({ onSubmit }) => {
  const [searchTerm1, setSearchTerm1] = useState('');
  const [selectedItem1, setSelectedItem1] = useState(null);
  const [industrySuggestions, setIndustrySuggestions] = useState([]);

  const [searchTerm2, setSearchTerm2] = useState('');
  const [selectedItem2, setSelectedItem2] = useState(null);
  const [locationSuggestions, setLocationSuggestions] = useState([]);

  const [errorMessage, setErrorMessage] = useState('');
  const MAX_SUGGESTIONS = 4; // Maximum number of suggestions to show
  const fadeOutItemRef = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      // Apply fading effect when the component updates
      if (fadeOutItemRef.current) {
          fadeOutItemRef.current.classList.add('fade-out');
      }
  
      // Fetch industries when the component mounts or when searchTerm1 changes
      try {
        const industriesResponse = await axios.get("https://django-hello-world-gamma-taupe.vercel.app/mailing/api/industry/");
        setIndustrySuggestions(industriesResponse.data);
  
        // Fetch locations after industries are fetched
        const locationsResponse = await axios.get("https://django-hello-world-gamma-taupe.vercel.app/mailing/api/location/");
        setLocationSuggestions(locationsResponse.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
  
    fetchData();
  }, [searchTerm1, searchTerm2]);
  

  const handleSubmit = (e) => {
    e.preventDefault();

    // Check if both questions are answered
    if (selectedItem1 && selectedItem2) {
      onSubmit({ industry_id: selectedItem1, location: selectedItem2 });
    } else {
      // Set the error message
      setErrorMessage('Please select answers for both questions.');
    }
  };

  const handleInputChange = (e, setTerm, setItem) => {
    setTerm(e.target.value);
    setItem(null); // Reset selected item when the user types
    setErrorMessage(''); // Clear the error message when the user types
  };

  const handleSelectItem = (item, setTerm, setItem) => {
    setTerm(item);
    setItem(item);
    setErrorMessage(''); // Clear the error message when an item is selected
  };

  return (
    <form onSubmit={handleSubmit}>
      <label>
        <h1>You want to reach whole industry with one message?</h1>
        <p>Please select industry that you want to reach, and provide your approximate location. We will find the best for you</p>
        <input
          type="text"
          value={searchTerm1}
          onChange={(e) => handleInputChange(e, setSearchTerm1, setSelectedItem1)}
          placeholder="I need ..."
        />
      </label>
      {searchTerm1 && (
        <ul className="suggestions">
          {industrySuggestions
            .filter((item) => typeof item === 'object' && item.name.toLowerCase().includes(searchTerm1.toLowerCase()))
            .slice(0, MAX_SUGGESTIONS + 1)
            .map((item, index) => (
              <li
                key={index}
                onClick={() => handleSelectItem(item.name, setSearchTerm1, setSelectedItem1)}
                ref={(ref) => (index === MAX_SUGGESTIONS ? (fadeOutItemRef.current = ref) : null)}
                className={`fade-${index + 1}`}
              >
                {item.name}
              </li>
            ))}
        </ul>
      )}

      <label>
        <input
          type="text"
          value={searchTerm2}
          onChange={(e) => handleInputChange(e, setSearchTerm2, setSelectedItem2)}
          placeholder="Vienna"
        />
      </label>
      {searchTerm2 && (
        <ul className="suggestions">
          {locationSuggestions
            .filter((item) => typeof item === 'object' && item.name.toLowerCase().includes(searchTerm2.toLowerCase()))
            .slice(0, MAX_SUGGESTIONS + 1)
            .map((item, index) => (
              <li
                key={index}
                onClick={() => handleSelectItem(item.name, setSearchTerm2, setSelectedItem2)}
                ref={(ref) => (index === MAX_SUGGESTIONS ? (fadeOutItemRef.current = ref) : null)}
                className={`fade-${index + 1}`}
              >
                {item.name}
              </li>
            ))}
        </ul>
      )}

      {errorMessage && <ErrorMessage message={errorMessage} />}

      <button type="submit">Continue</button>
    </form>
  );
};

export default Form1;
