// src/components/common/Header.jsx
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../../styles/header.css'; // Import the CSS file
import wildcardLogo from '../../styles/logo_small.png'; // Import the image

const Header = () => {
  const navigate = useNavigate();

  const handleHomeClick = () => {
    // Handle the Home button click
    navigate('/');
  };

  return (
    <header className="header">
      <div className="left">
        <button onClick={handleHomeClick}>
          <img src={wildcardLogo} alt="Wildcard Logo" />
        </button>
      </div>
      <div className="right">
        <Link to="/about">About</Link>
        <Link to="/for-companies">For Companies</Link>
      </div>
    </header>
  );
};

export default Header;
