import React, { useState } from 'react';
import Form1 from './Form1';
import Form2 from './Form2';
import ConfirmationPage from './ConfirmationPage';

const Home = () => {
  const [formData, setFormData] = useState(null);
  const [showConfirmationPage, setShowConfirmationPage] = useState(false);

  const handleForm1Submit = (data) => {
    setFormData(data);
  };

  const handleForm2Submit = (data) => {
    setShowConfirmationPage(true);
    // Handle the final submission with all data
    console.log('Final Form Data:', data);
  };

  return (
    <div>
      {showConfirmationPage ? (
        <ConfirmationPage data={formData} />
      ) : formData ? (
        <Form2 data={formData} onSubmit={handleForm2Submit} />
      ) : (
        <Form1 onSubmit={handleForm1Submit} />
      )}
    </div>
  );
};

export default Home;
