// src/components/CompanyForm.jsx
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ErrorMessage from '../components/common/ErrorMessage';
import axios from 'axios';

const CompanyForm = () => {
  const navigate = useNavigate();
  const [company_name, setCompanyName] = useState('');
  const [industry_id, setIndustry] = useState('');
  const [location, setLocation] = useState('');
  const [company_email, setCompanyEmail] = useState('');

  const [errorMessage, setErrorMessage] = useState('');

  const handleCompanyFormSubmit = async (e) => {
    e.preventDefault();

    if (company_name && industry_id && location && company_email) {
      try {
        const response = await axios.post("https://django-hello-world-gamma-taupe.vercel.app/mailing/api/companies/", {
          company_name,
          industry_id,
          location,
          company_email,
        });

        if (response.status === 201) {
          console.log('Form submitted successfully!');
          navigate('/company-confirmation'); // Navigate to the confirmation page
        } else {
          console.error('Form submission failed.');
        }
      } catch (error) {
        console.error('Error during form submission:', error);
      }
    } else {
      setErrorMessage('Please fill in all fields.');
    }
  };

  return (
    <div>
      <h2>Company Form</h2>
      <form onSubmit={handleCompanyFormSubmit}>
        <label>
          Company Name:
          <input
            type="text"
            value={company_name}
            onChange={(e) => setCompanyName(e.target.value)}
            placeholder="Enter company name..."
          />
        </label>

        <label>
          Industry:
          <select value={industry_id} onChange={(e) => setIndustry(e.target.value)}>
            <option value="">Select an industry</option>
            <option value="technology">Technology</option>
            <option value="finance">Finance</option>
            {/* Add more options as needed */}
          </select>
        </label>

        <label>
          Location:
          <select value={location} onChange={(e) => setLocation(e.target.value)}>
            <option value="">Select a location</option>
            <option value="cityA">City A</option>
            <option value="cityB">City B</option>
            {/* Add more options as needed */}
          </select>
        </label>
        <input
          type="email"
          value={company_email}
          onChange={(e) => setCompanyEmail(e.target.value)}
          placeholder="Enter company email..."
        />

        {errorMessage && <ErrorMessage message={errorMessage} />}

        <button type="submit">Submit</button>
      </form>
    </div>
  );
};

export default CompanyForm;
