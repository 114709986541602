// src/components/CompanyConfirmationPage.jsx
import React from 'react';

const CompanyConfirmationPage = ({ location }) => {

  return (
    <div>
      <h2>Company Confirmation</h2>
      <p>Thank you for submitting your company data!</p>
    </div>
  );
};

export default CompanyConfirmationPage;
