import React from 'react';
import './app.css'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/common/Header';
import Footer from './components/common/Footer';
import Home from './components/Home';
import About from './components/common/About';
import CompanyForm from './components/CompanyForm';
import CompanyConfirmationPage from './components/CompanyConfirmationPage';
import Agb from './components/common/Agb';
import Terms from './components/common/Terms';
import Faq from './components/common/Faq';
import Impressum from './components/common/Impressum';
import ConfirmationPage from './components/ConfirmationPage';

const App = () => {
  return (
    <Router>
      <div className="gradient-background">
        <Header />
          <main>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/for-companies" element={<CompanyForm />} />
              <Route path="/company-confirmation" element={<CompanyConfirmationPage />} />
              <Route path="/agb" element={<Agb />} />
              <Route path="/terms" element={<Terms />} />
              <Route path="/faq" element={<Faq />} />
              <Route path="/impressum" element={<Impressum />} />
              <Route path="/confirmation-page" element={<ConfirmationPage />} />
            </Routes>
          </main>
        <Footer />
      </div>
    </Router>
  );
};

export default App;
