// src/components/Form2.jsx
import React, { useState } from 'react';
import '../styles/form2.css';
import ErrorMessage from '../components/common/ErrorMessage';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const Form2 = ({ data, onSubmit }) => {
  const [subject, setSubject] = useState('');
  const [email_body, setEmailBody] = useState('');
  const [user_email, setUserEmail] = useState('');

  const [errorMessage, setErrorMessage] = useState('');

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if all fields are filled
    if (subject && email_body && user_email) {
      try {
        const response = await axios.post("https://django-hello-world-gamma-taupe.vercel.app/mailing/api/emailforms/", {
          ...data,
          subject,
          email_body,
          user_email,
        });

        if (response.status === 201) {
          console.log('Form submitted successfully!');
          navigate('/confirmation-page'); // Navigate to the confirmation page
        } else {
          console.error('Form submission failed.');
        }
      } catch (error) {
        console.error('Error during form submission:', error);
      }
    } else {
      setErrorMessage('Please fill in all fields.');
    }
  };

  const handleInfoClick = (infoText) => {
    alert(infoText); // You can replace this with your preferred way of displaying additional information.
  };

  return (
    <form className="form-container" onSubmit={handleSubmit}>
      <div className="form-row">
        <label className="form-label">
          Subject for your email
          <span className="info-button" onClick={() => handleInfoClick('Additional information for the subject.')}>
            ℹ️
          </span>
        </label>
        <input
          type="text"
          value={subject}
          onChange={(e) => setSubject(e.target.value)}
          maxLength={200}
          placeholder="Enter subject..."
        />
      </div>

      <div className="form-row">
        <label className="form-label">
          Email body (we have a limit of 2000 characters)
          <span className="info-button" onClick={() => handleInfoClick('Additional information for the email body.')}>
            ℹ️
          </span>
        </label>
        <textarea
          value={email_body}
          onChange={(e) => setEmailBody(e.target.value)}
          maxLength={2000}
          placeholder="Write your email ..."
          className="bigger-text"
        />
      </div>

      <div className="form-row">
        <label className="form-label">
          Email
          <span className="info-button" onClick={() => handleInfoClick('Enter your email address for communication.')}>
            ℹ️
          </span>
        </label>
        <input
          type="email"
          value={user_email}
          onChange={(e) => setUserEmail(e.target.value)}
          placeholder="Enter email..."
        />
      </div>

      {errorMessage && <ErrorMessage message={errorMessage} />}

      <button type="submit">Send email</button>
    </form>
  );
};

export default Form2;
